import VStyle from './VStyle.vue'
import AppModal from './AppModal.vue'
import AppLoader from './AppLoader.vue'
import formComponents from './forms/index'
import AppWaypoint from './AppWaypoint.vue'
import AppFreeForm from './AppFreeForm.vue'
import MarketoForm from './MarketoForm.vue'
import AppAccordion from './AppAccordion.vue'
import AppAccordionToggleAllButton from './AppAccordionToggleAllButton.vue'
import AppDropdownLinks from './AppDropdownLinks.vue'
import AppCookieBanner from './AppCookieBanner.vue'

const globalComponents = [
  VStyle,
  AppModal,
  AppLoader,
  AppFreeForm,
  AppWaypoint,
  MarketoForm,
  AppAccordion,
  ...formComponents,
  AppAccordionToggleAllButton,
  AppDropdownLinks,
  AppCookieBanner,
]

export default globalComponents
